<template>
	<b-card title="FAQ Content List">
		<b-button
			variant="primary"
			class="btn waves-effect waves-float waves-primary btn-primary mb-2"
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			v-b-modal.modalCreate
			@click="cleanUpForm()"
		>
			Create Faq Category
		</b-button>
		<b-modal
			id="modalCreate"
			centered
			title="Create FAQ Category"
			hide-footer
		>
			<form @submit.prevent="saveItem">
				<div class="form-group">
					<label>Nama Category: </label>
					<input 
						type="text" 
						class="form-control" 
						v-model="formCategory.name"
						placeholder="Nama Category"
					>
				</div>
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary mt-2"
				>
					Save
				</button>
			</form>
		</b-modal>
		<b-table 
			striped 
			hover 
			:items="faqsCategory"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * 10 + index + 1 }}
			</template>	
			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<b-badge 
						variant="warning"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-info-icon`"
							icon="EditIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Edit Data'"
							@click="editItem(item)"
							v-if="checkPermission('update page')"
						/>
					</b-badge>

					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-delete-icon`"
							icon="TrashIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							v-if="checkPermission('delete faq category')"
							@click="deleteItem(item.uuid)"
						/>
					</b-badge>
				</div>
			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
		<b-modal
			id="modalEdit"
			centered
			title="Create FAQ Category"
			hide-footer
		>
			<form @submit.prevent="updateItem(formPayload)">
				<div class="form-group">
					<label>Nama Category: </label>
					<input 
						type="text" 
						class="form-control" 
						v-model="formPayload.name"
						placeholder="Nama Category"
					>
				</div>
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary mt-2"
				>
					Save
				</button>
			</form>
		</b-modal>
	</b-card>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBModal,
	BFormSelect,
	VBTooltip
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
		BFormSelect
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			result:{},
			fields: ['no', 'name', 'actions'],
			faqsCategory: [],
			filter: {
				keyword: ''
			},
			formCategory: {
				name: ''
			},
			isLoading: false,
			formPayload: Object,
		}
	},

	computed: {
		rows() {
			return this.faqsCategory.length
		}
	},

	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},

	methods: {
		cleanUpForm() {
			this.formCategory = {
				name: '',
			}
		},
		getData(page) { //page = 1
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/faq-categories', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.faqsCategory = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
		saveItem() {
			this.$http.post('admin/faq-categories', this.formCategory)
			.then(response => {
				this.getData()
				this.cleanUpForm()
				successNotification(this, 'Success', 'FAQ Category Sukses Dibuat!')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama Category', this.validations.name.join(' '))
				}
			})
			this.$nextTick(() => {
				this.$bvModal.hide('modalCreate')
			})
		},
		editItem(item) {
			this.cleanUpForm();
			this.formPayload = {
				uuid: item.uuid,
				name: item.name,
			}
			this.$bvModal.show('modalEdit')
		},
		updateItem(item) {
			item._method = 'PATCH'
			this.$http.post('admin/faq-categories/' + item.uuid, item)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Page Berhasil Diedit!')
				this.$bvModal.hide('modalEdit')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama Category', this.validations.name.join(' '))
				}
			})
		},
		deleteItem(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus FAQ Category ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/faq-categories/' + item)
					this.getData()
					successNotification(this, 'Success', 'Faq Category berhasil dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},

	created() {
		this.getData()
	}
}
</script>